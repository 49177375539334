.container {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.clearBtn {
  margin-top: 4px;
  color: white;
  background-color: black;
  border: 1px solid white;
}
.clearBtn:hover {
  border-color: black;
  background-color: rgb(39, 4, 11);
  font-weight: bold;
}

.copyEditor {
  /* width:70vw; */
  /* background-color: azure; */
  /* height: 100%; */
  /* flex: 1; */
}