.hidden {
  display: none;
}

.textarea {
  width: 100%;
  height: 70vh;
  background-color: black;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 4px;
  font-size: 40px;
  resize: none;
  margin-bottom: 30px;
  color: #fff;
}

.btnRandom {
  color: #fff;
  background-color: black;
  height: 35px;
  cursor: pointer;
  border: 1px solid white;
  margin-bottom: 4px;
  /* display: none; */
}

.btnReset:hover {
  border-color: #fff;
}