.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.716);
  z-index: 99;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalCopy {
  width: 40%;
  min-width: 300px;
  min-height: 300px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 20px;
  border-width: 0;
  padding: 0 10px;
}