nav{
	width: 100%;
	height: 75px;
	margin: 0;
	padding: 0 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
}
nav button {
	color: #ffffff;
	background-color: black;
	border: 1px solid #000;
	border-radius: 8px;
	width: 65px;
	height: 40px;
	cursor: pointer;
}
nav button:hover {
	border-color: #ffffff;
}

.branding{
	font-size: 2rem;
}
