.container {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
}
button {
  font-size: medium;
  height: 30px;
  width: 100px;
  margin: 0 5px;
  border: 0px solid black;
  border-radius: 5px;
  color: grey;
  cursor: pointer;
}
.active {
  background-color: rgb(72, 108, 133);
  font-weight: bold;
  border-color: #fff;
  color: #fff;
}