.container {
	background-color: rgb(255, 255, 255);
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: 4px;
	display: flex;
	flex-direction: column;
	align-items: flex-start; 
	justify-content: flex-start;
}

.editorControls {
	height: 45px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	background-color: rgb(216, 228, 230);
}
.editorControls > select {
	margin-right: 20px;
}
.editorControls > label {
	color: black;
	margin-right: 5px;
}

.clearBtn {
	color: grey;
	background-color: white;
	border: 1px solid grey;
}

.clearBtn:hover {
	border-color: rgb(216, 228, 230);
	color: white;
	background-color: rgb(127, 0, 25);
	font-weight: bold;
}

.primaryEditor {
	flex: 1;
}