.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.promptArea {
  max-width: 40%;
  height: 100vh;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}

.editorArea {
  flex: 1;
  background-color: blueviolet;
  height: 100vh;
  width: 60vw;
}